import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Feelings from './Feelings';
import Needs from './Needs';

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/feelings" element={<Feelings />} />
          <Route path="/needs" element={<Needs />} />
        </Routes>
      </Router>
  );
}

export default App;
