import React, { useState, useRef } from 'react';
import LoadingBar from 'react-top-loading-bar';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './App.css';

function Review({ title, placeholder, url, type, showBackButton }) {
    const [inputValue, setInputValue] = useState('');
    const [words, setWords] = useState([]);
    const [labels, setLabels] = useState({});
    const [expanded, setExpanded] = useState({});
    const loadingBarRef = useRef(null);
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (inputValue.trim() !== '') {
            setWords([...words, inputValue]);
        }
        setInputValue(''); // Clear the input field after submitting
    };

    const handleReview = async () => {
        const data = {
            words: words.join(', ')
        };
        try {
            loadingBarRef.current.continuousStart();
            const response = await axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setLabels(response.data);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            loadingBarRef.current.complete();
        }
    };

    const getLabelIcon = (label) => {
        if (label === undefined) {
            return '';
        }
        const l = label.toLowerCase();
        switch (l) {
            case type:
                return '✅';
            case 'judgement':
            case 'interpretation':
            case 'thought':
            case 'strategy':
                return `✖️ (${l})`;
            default:
                return `✖️ (other)`;;
        }
    };

    const toggleExplanation = (word) => {
        setExpanded(prev => ({ ...prev, [word]: !prev[word] }));
    };
    const clearList = () => {
        setWords([]);
        setLabels({});
    };

    return (
        <div className="flex h-screen items-center justify-center bg-gray-100">
            <LoadingBar color="#f11946" ref={loadingBarRef} />
            <div className="w-full max-w-md mx-auto p-8 bg-white shadow-lg rounded-lg">
                <div className="flex justify-between mb-4">
                    {showBackButton && (
                        <button
                            onClick={() => navigate(-1)}
                            className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                        >
                            &larr; Back
                        </button>
                    )}
                    <button
                        onClick={clearList}
                        className="px-4 py-2 bg-yellow-300 text-yellow-800 rounded-md hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50 ml-auto"
                    >
                        Clear List
                    </button>
                </div>
                <h1 className="text-2xl font-bold text-center mb-6 text-gray-800">{title}</h1>
                <form onSubmit={handleSubmit} className="mb-4">
                    <input
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        className="w-full p-3 border border-gray-300 rounded-md mb-2"
                        placeholder={placeholder}
                    />
                    <button type="submit" className="w-full p-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 mb-4">
                        Submit
                    </button>
                </form>
                <div className="max-h-64 overflow-y-auto mb-4">
                    <ul className="space-y-2">
                        {words.map((word, index) => (
                            <li
                                key={index}
                                className="p-3 bg-gray-100 rounded-md flex flex-col justify-between items-start cursor-pointer"
                                onClick={() => toggleExplanation(word)}
                            >
                                <div className="flex justify-between w-full">
                                    <span>{word}</span>
                                    <span>{getLabelIcon(labels[word]?.label)}</span>
                                </div>
                                {labels[word] && labels[word].label !== type && expanded[word] && (
                                    <div className="mt-2 p-2 bg-gray-200 rounded-md">
                                        <p className="text-sm text-gray-700">{labels[word].explanation}</p>
                                        <p className="text-sm text-gray-800 mt-2">{labels[word].suggestion}</p>
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
                {words.length > 0 && (
                    <button
                        onClick={handleReview}
                        className="w-full p-3 bg-green-500 text-white rounded-md hover:bg-green-600"
                    >
                        Review
                    </button>
                )}
            </div>
        </div>
    );
}

export default Review;