import React from 'react';
import Review from './Review';

function Feelings() {
    return (
        <Review
            title="Write your emotions one by one"
            placeholder="Type an emotion..."
            url="/.netlify/functions/feelings-proxy"
            type="emotion"
            showBackButton={true}
        />
    );
}

export default Feelings;
