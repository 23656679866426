import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
    return (
        <div className="flex h-screen items-center justify-center bg-gray-100">
            <div className="w-full max-w-md mx-auto p-8 bg-white shadow-lg rounded-lg">
                <h1 className="text-2xl font-bold text-center mb-6 text-gray-800">NVC Tools</h1>
                <div className="flex flex-col space-y-4">
                    <Link to="/feelings" className="w-full p-3 bg-blue-500 text-white rounded-md text-center hover:bg-blue-600">
                        Review your feelings
                    </Link>
                    <Link to="/needs" className="w-full p-3 bg-green-500 text-white rounded-md text-center hover:bg-green-600">
                        Review your needs
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Home;
