import React from 'react';
import Review from './Review';

function Needs() {
    return (
        <Review
            title="Write your needs one by one"
            placeholder="Type a need..."
            url="/.netlify/functions/needs-proxy"
            type="need"
            showBackButton={true}
        />
    );
}

export default Needs;
